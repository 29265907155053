<div class="bg-white300">
  <form [formGroup]="form" class="container py-6 px-2 sm:px-6">
    <div class="bg-white clip-path-box">
      <div class="flex items-center justify-between py-6 px-6 pl-14">
        <img src="assets/svg/icons/logo-example.svg" alt="Logo" />
        <img
          src="assets/svg/icons/pagamento-seguro.svg"
          alt="Pagamento Seguro" />
      </div>

      <!-- <div
        class="w-full h-[1px] bg-gradient-to-r from-transparent via-rose300 to-transparent my-3"></div> -->
      <div class="flex justify-center">
        <img
          src="assets/svg/icons/line-horizontal.svg"
          alt="Pagamento Seguro"
          class="max-w-[1200px] w-full" />
      </div>

      <div class="md:flex justify-center gap-10 py-4 px-6 mt-6">
        <div class="flex-1">
          <!-- Sessão Contato -->
          <p-accordion [multiple]="true" [activeIndex]="[0, 1, 2, 3]">
            <p-accordionTab header="Contato">
              <fieldset formGroupName="step1" class="flex flex-col gap-3">
                <div class="flex flex-col gap-1">
                  <app-input
                    type="text"
                    formControlName="email"
                    placeholder="E-mail"
                    size="small"
                    color="white"
                    className="w-full"
                    required />

                  <p
                    class="text-red-600 text-sm"
                    *ngIf="
                      form.get('step1.email')?.touched &&
                      form.get('step1.email')?.invalid
                    ">
                    E-mail inválido
                  </p>
                </div>

                <div class="flex align-items-center gap-2">
                  <p-checkbox
                    formControlName="newsletter"
                    value="newsletter"
                    inputId="newsletter" />
                  <label for="newsletter"
                    >Se inscreva para receber novidades e ofertas</label
                  >
                </div>

                <app-button
                  *ngIf="currentStep === 1"
                  size="small"
                  color="green"
                  className="w-full transition-all font-semibold h-12 mt-4"
                  (click)="nextStep()"
                  >Avançar</app-button
                >
              </fieldset>
            </p-accordionTab>

            <div
              [hidden]="currentStep < 1"
              class="w-full h-[1px] bg-gradient-to-r from-transparent via-rose300 to-transparent mt-3"></div>

            <p-accordionTab
              header="Entrega"
              [ngClass]="{
                'opacity-40 pointer-events-none': currentStep === 1,
              }">
              <fieldset
                formGroupName="step2"
                class="flex flex-col gap-3"
                *ngIf="currentStep >= 1">
                <div class="grid grid-cols-1 gap-3 md:grid-cols-2">
                  <div class="flex flex-col gap-1">
                    <app-input
                      type="text"
                      formControlName="firstName"
                      placeholder="Primeiro nome"
                      size="small"
                      color="white"
                      className="w-full"
                      required />

                    <p
                      class="text-red-600 text-sm"
                      *ngIf="
                        form.get('step2.firstName')?.touched &&
                        form.get('step2.firstName')?.invalid
                      ">
                      Primeiro nome é obrigatório
                    </p>
                  </div>

                  <div class="flex flex-col gap-1">
                    <app-input
                      type="text"
                      formControlName="lastName"
                      placeholder="Sobrenome"
                      size="small"
                      color="white"
                      className="w-full"
                      required />

                    <p
                      class="text-red-600 text-sm"
                      *ngIf="
                        form.get('step2.lastName')?.touched &&
                        form.get('step2.lastName')?.invalid
                      ">
                      Sobrenome é obrigatório
                    </p>
                  </div>
                </div>

                <div class="flex flex-col gap-1">
                  <app-input
                    type="text"
                    formControlName="phone"
                    placeholder="Telefone"
                    size="small"
                    color="white"
                    className="w-full"
                    mask="(00) 00000-0000" />

                  <div
                    class="text-red-600 text-sm"
                    *ngIf="
                      form.get('step2.phone')?.touched &&
                      form.get('step2.phone')?.invalid
                    ">
                    <p *ngIf="form.get('step2.phone')?.hasError('pattern')">
                      Formato de telefone incorreto
                    </p>
                    <p *ngIf="form.get('step2.phone')?.hasError('required')">
                      Telefone é obrigatório
                    </p>
                  </div>
                </div>

                <div class="flex flex-col gap-1">
                  <app-input
                    formControlName="cep"
                    type="text"
                    placeholder="CEP"
                    size="small"
                    color="white"
                    className="w-full"
                    mask="00000-000"
                    (input)="onCepChange($event)"
                    maxlength="9"
                    iconName="pi-search"
                    required />

                  <div
                    class="text-red-600 text-sm"
                    *ngIf="
                      form.get('step2.cep')?.touched &&
                      form.get('step2.cep')?.invalid
                    ">
                    <p *ngIf="form.get('step2.cep')?.hasError('pattern')">
                      Formato de CEP incorreto
                    </p>
                    <p *ngIf="form.get('step2.cep')?.hasError('required')">
                      CEP é obrigatório
                    </p>
                  </div>
                </div>

                <div class="flex flex-col gap-1">
                  <app-input
                    formControlName="street"
                    type="text"
                    placeholder="Endereço"
                    size="small"
                    color="white"
                    className="w-full"
                    required />

                  <p
                    class="text-red-600 text-sm"
                    *ngIf="
                      form.get('step2.street')?.touched &&
                      form.get('step2.street')?.invalid
                    ">
                    Endereço é obrigatório
                  </p>
                </div>

                <app-input
                  type="text"
                  placeholder="Apartamento, casa, etc.. (opcional)"
                  size="small"
                  color="white"
                  className="w-full" />

                <div class="grid grid-cols-1 gap-3 md:grid-cols-2">
                  <div class="flex flex-col gap-1">
                    <app-input
                      formControlName="city"
                      type="text"
                      placeholder="Cidade"
                      size="small"
                      color="white"
                      className="w-full"
                      required />

                    <p
                      class="text-red-600 text-sm"
                      *ngIf="
                        form.get('step2.city')?.touched &&
                        form.get('step2.city')?.invalid
                      ">
                      Cidade é obrigatório
                    </p>
                  </div>

                  <div class="flex flex-col gap-1">
                    <app-input
                      formControlName="state"
                      type="text"
                      placeholder="Estado"
                      size="small"
                      color="white"
                      className="w-full"
                      maxlength="2"
                      required />

                    <div
                      class="text-red-600 text-sm"
                      *ngIf="
                        form.get('step2.state')?.touched &&
                        form.get('step2.state')?.invalid
                      ">
                      <p *ngIf="form.get('step2.state')?.hasError('pattern')">
                        Formato de Estado incorreto, deve ser apenas UF.
                        Exemplo: SP
                      </p>
                      <p *ngIf="form.get('step2.state')?.hasError('required')">
                        Estado é obrigatório
                      </p>
                    </div>
                  </div>
                </div>

                <app-button
                  *ngIf="currentStep === 2"
                  size="small"
                  color="green"
                  className="w-full transition-all font-semibold h-12 mt-4"
                  (click)="nextStep()"
                  >Avançar</app-button
                >
              </fieldset>
            </p-accordionTab>

            <div
              [hidden]="currentStep < 2"
              class="w-full h-[1px] bg-gradient-to-r from-transparent via-rose300 to-transparent mt-3"></div>

            <p-accordionTab
              header="Método de envio"
              [ngClass]="{
                'opacity-40 pointer-events-none': currentStep === 2,
              }"
              [hidden]="currentStep < 2">
              <fieldset formGroupName="step3" class="flex flex-col gap-3">
                <div>
                  <p class="text-gray400">
                    Entrega para o CEP: {{ cep || '-' }}
                  </p>
                </div>

                <!-- Opção Sedex -->
                <div *ngFor="let shipping of shippingMethods">
                  <label
                    class="bg-white300 rounded-lg flex justify-between items-center px-6 py-4 cursor-pointer">
                    <input
                      type="radio"
                      formControlName="shippingMethod"
                      [value]="shipping.title"
                      class="hidden"
                      (change)="selectShippingMethod(shipping.title)" />
                    <div class="flex items-center gap-4">
                      <div
                        class="rounded-full h-4 min-w-4 flex items-center justify-center"
                        [ngClass]="{
                          'bg-black500':
                            selectedShippingMethod === shipping.title,
                          'bg-white': selectedShippingMethod !== shipping.title,
                        }">
                        <div
                          *ngIf="selectedShippingMethod === shipping.title"
                          class="bg-white rounded-full h-1.5 w-1.5"></div>
                      </div>
                      <div class="ml-2 leading-3 text-left">
                        <p class="uppercase">{{ shipping.title }}</p>
                        <p class="text-sm text-gray500">
                          <span
                            *ngIf="
                              shipping.minDays === 0 && shipping.maxDays === 0
                            ">
                            Entrega hoje
                          </span>
                          <span
                            *ngIf="
                              shipping.minDays === shipping.maxDays &&
                              shipping.minDays !== 0
                            ">
                            {{ shipping.minDays + ' dias úteis' }}
                          </span>
                          <span *ngIf="shipping.minDays !== shipping.maxDays">
                            {{
                              shipping.minDays === 0
                                ? 'Hoje à ' + shipping.maxDays + ' dias úteis'
                                : shipping.minDays +
                                  ' à ' +
                                  shipping.maxDays +
                                  ' dias úteis'
                            }}
                          </span>
                        </p>
                      </div>
                    </div>
                    <p>
                      {{
                        shipping.price === 0
                          ? 'Grátis'
                          : 'R$ ' + (shipping.price | number: '1.2-2')
                      }}
                    </p>
                  </label>
                </div>

                <p class="text-gray300">
                  O prazo de entrega não contabiliza feriados.
                </p>

                <app-button
                  *ngIf="currentStep === 3"
                  size="small"
                  color="green"
                  className="w-full transition-all font-semibold h-12 mt-4"
                  (click)="nextStep()">
                  Avançar
                </app-button>
              </fieldset>
            </p-accordionTab>

            <div
              [hidden]="currentStep < 3"
              class="w-full h-[1px] bg-gradient-to-r from-transparent via-rose300 to-transparent mt-3"></div>

            <p-accordionTab
              header="Métodos de Pagamento"
              [ngClass]="{
                'opacity-40 pointer-events-none': currentStep === 3,
              }"
              [hidden]="currentStep < 3">
              <fieldset formGroupName="step4" class="flex flex-col gap-3">
                <div>
                  <p class="text-gray400">
                    Todas as transações são seguras e criptografadas.
                  </p>
                  <p class="text-gray400 leading-3">
                    Escolha uma das formas de pagamento abaixo:
                  </p>
                </div>

                <label
                  *ngIf="pixActive"
                  class="bg-white300 rounded-lg flex justify-between items-center px-6 py-4 cursor-pointer">
                  <input
                    type="radio"
                    formControlName="paymentMethod"
                    value="pix"
                    class="hidden"
                    (change)="selectPaymentMethod('pix')" />
                  <div class="flex items-center gap-4">
                    <div
                      class="rounded-full bg-white h-4 min-w-4 flex items-center justify-center"
                      [ngClass]="{
                        'bg-black500': selectedPaymentMethod === 'pix',
                        'bg-white': selectedPaymentMethod !== 'pix',
                      }">
                      <div
                        *ngIf="selectedPaymentMethod === 'pix'"
                        class="bg-white rounded-full h-1.5 w-1.5"></div>
                    </div>

                    <div class="ml-2 flex items-center gap-2">
                      <img
                        src="assets/svg/icons/pix-icon.svg"
                        alt="Ícone PIX"
                        class="w-6" />
                      <p>PIX</p>
                    </div>
                  </div>
                </label>

                <label
                  class="bg-white300 rounded-lg flex justify-between items-center px-6 py-4 cursor-pointer">
                  <input
                    type="radio"
                    formControlName="paymentMethod"
                    value="boleto"
                    class="hidden"
                    (change)="selectPaymentMethod('boleto')" />
                  <div class="flex items-center gap-4">
                    <div
                      class="rounded-full bg-white h-4 min-w-4 flex items-center justify-center"
                      [ngClass]="{
                        'bg-black500': selectedPaymentMethod === 'boleto',
                        'bg-white': selectedPaymentMethod !== 'boleto',
                      }">
                      <div
                        *ngIf="selectedPaymentMethod === 'boleto'"
                        class="bg-white rounded-full h-1.5 w-1.5"></div>
                    </div>

                    <div class="ml-2 flex items-center gap-2">
                      <img
                        src="assets/svg/icons/boleto-icon.svg"
                        alt="Ícone Boleto"
                        class="w-6" />
                      <p>Boleto</p>
                    </div>
                  </div>
                </label>

                <label
                  *ngIf="cardActive"
                  class="bg-white300 rounded-lg flex justify-between items-center px-6 py-4 cursor-pointer">
                  <input
                    type="radio"
                    formControlName="paymentMethod"
                    value="credit"
                    class="hidden"
                    (change)="selectPaymentMethod('credit')" />
                  <div class="flex items-center gap-4">
                    <div
                      class="rounded-full bg-white h-4 min-w-4 flex items-center justify-center"
                      [ngClass]="{
                        'bg-black500': selectedPaymentMethod === 'credit',
                        'bg-white': selectedPaymentMethod !== 'credit',
                      }">
                      <div
                        *ngIf="selectedPaymentMethod === 'credit'"
                        class="bg-white rounded-full h-1.5 w-1.5"></div>
                    </div>

                    <div class="ml-2 flex items-center gap-2">
                      <img
                        src="assets/svg/icons/credit-card-icon.svg"
                        alt="Ícone Cartão de Crédito"
                        class="w-6" />
                      <p>Cartão de crédito</p>
                    </div>
                  </div>

                  <div class="flex justify-end gap-1 flex-wrap">
                    <img
                      src="assets/svg/icons/credits/amex.svg"
                      alt="Amex"
                      title="Amex" />
                    <img
                      src="assets/svg/icons/credits/visa.svg"
                      alt="Visa"
                      title="Visa" />
                    <img
                      src="assets/svg/icons/credits/diners.svg"
                      alt="Diners"
                      title="Diners" />
                    <img
                      src="assets/svg/icons/credits/mastercard.svg"
                      alt="Master Card"
                      title="Master Card" />
                    <img
                      src="assets/svg/icons/credits/discover.svg"
                      alt="Discover"
                      title="Discover" />
                    <img
                      src="assets/svg/icons/credits/aura.svg"
                      alt="Aura"
                      title="Aura" />
                    <img
                      src="assets/svg/icons/credits/hipercard.svg"
                      alt="Hipercard"
                      title="Hipercard" />
                  </div>
                </label>

                <div
                  class="flex flex-col gap-3"
                  *ngIf="selectedPaymentMethod === 'credit'">
                  <div class="flex flex-col gap-1">
                    <app-input
                      type="text"
                      formControlName="cardNumber"
                      placeholder="Número do cartão"
                      size="small"
                      color="white"
                      className="w-full"
                      iconName="pi-lock"
                      mask="0000000000000000"
                      required />

                    <div
                      class="text-red-600 text-sm"
                      *ngIf="
                        form.get('step4.cardNumber')?.touched &&
                        form.get('step4.cardNumber')?.invalid
                      ">
                      <p
                        *ngIf="
                          form.get('step4.cardNumber')?.hasError('pattern')
                        ">
                        Número de cartão incorreto
                      </p>
                      <p
                        *ngIf="
                          form.get('step4.cardNumber')?.hasError('required')
                        ">
                        Número do cartão é obrigatório
                      </p>
                    </div>
                  </div>

                  <div class="grid grid-cols-1 gap-3 md:grid-cols-2">
                    <div class="flex flex-col gap-1">
                      <app-input
                        type="text"
                        formControlName="expiryDate"
                        placeholder="Data de validade (MM/AA)"
                        size="small"
                        color="white"
                        className="w-full"
                        mask="00/00"
                        required />

                      <div
                        class="text-red-600 text-sm"
                        *ngIf="
                          form.get('step4.expiryDate')?.touched &&
                          form.get('step4.expiryDate')?.invalid
                        ">
                        <p
                          *ngIf="
                            form.get('step4.expiryDate')?.hasError('pattern')
                          ">
                          Data de validade incorreta
                        </p>
                        <p
                          *ngIf="
                            form.get('step4.expiryDate')?.hasError('required')
                          ">
                          Data de validade é obrigatória
                        </p>
                      </div>
                    </div>

                    <div class="flex flex-col gap-1">
                      <app-input
                        type="text"
                        formControlName="cvv"
                        placeholder="Código de segurança"
                        size="small"
                        color="white"
                        className="w-full"
                        iconName="pi-question-circle"
                        mask="0000"
                        pTooltipIcon="Você encontrará o número de três ou quatro dígitos no verso do seu cartão"
                        tooltipPositionIcon="bottom"
                        required />

                      <div
                        class="text-red-600 text-sm"
                        *ngIf="
                          form.get('step4.cvv')?.touched &&
                          form.get('step4.cvv')?.invalid
                        ">
                        <p *ngIf="form.get('step4.cvv')?.hasError('pattern')">
                          Código de segurança incorreto
                        </p>
                        <p *ngIf="form.get('step4.cvv')?.hasError('required')">
                          Código de segurança é obrigatório
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="flex flex-col gap-1">
                    <p-dropdown
                      formControlName="selectedInstallment"
                      [options]="installments"
                      optionLabel="name"
                      placeholder="Número de parcelas" />

                    <p
                      class="text-red-600 text-sm"
                      *ngIf="
                        form.get('step4.selectedInstallment')?.touched &&
                        form.get('step4.selectedInstallment')?.invalid
                      ">
                      Número de parcelas é obrigatório
                    </p>
                  </div>

                  <div class="flex flex-col gap-1">
                    <app-input
                      type="text"
                      formControlName="cardHolderName"
                      placeholder="Nome no cartão"
                      size="small"
                      color="white"
                      className="w-full"
                      required />

                    <p
                      class="text-red-600 text-sm"
                      *ngIf="
                        form.get('step4.cardHolderName')?.touched &&
                        form.get('step4.cardHolderName')?.invalid
                      ">
                      Nome no cartão é obrigatório
                    </p>
                  </div>

                  <div class="flex flex-col gap-1">
                    <app-input
                      type="text"
                      formControlName="cardHolderCpf"
                      placeholder="CPF do titular do cartão"
                      size="small"
                      color="white"
                      className="w-full"
                      mask="000.000.000-00"
                      required />

                    <p
                      class="text-red-600 text-sm"
                      *ngIf="
                        form.get('step4.cardHolderCpf')?.touched &&
                        form.get('step4.cardHolderCpf')?.invalid
                      ">
                      CPF do titular do cartão é obrigatório
                    </p>

                    <div
                      class="text-red-600 text-sm"
                      *ngIf="
                        form.get('step4.cardHolderCpf')?.touched &&
                        form.get('step4.cardHolderCpf')?.invalid
                      ">
                      <p
                        *ngIf="
                          form.get('step4.cardHolderCpf')?.hasError('required')
                        ">
                        CPF do titular do cartão é obrigatório
                      </p>
                    </div>
                  </div>

                  <div class="flex align-items-center gap-2">
                    <p-checkbox
                      formControlName="billingAddress"
                      value="billingAddress"
                      inputId="billingAddress" />
                    <label for="billingAddress"
                      >Usar endereço de entrega como endereço de cobrança</label
                    >
                  </div>
                </div>
              </fieldset>
            </p-accordionTab>

            <!-- Responsive mobile -->
            <div class="flex-1 block lg:hidden">
              <div
                class="w-full h-[1px] bg-gradient-to-r from-transparent via-rose300 to-transparent my-3"></div>
              <div
                class="bg-white300 clip-path-resume flex flex-col gap-3 sticky top-5">
                <div class="pt-4 px-6">
                  <p class="font-semibold text-xl">Resumo da compra</p>
                </div>

                <div
                  class="w-full h-[1px] bg-gradient-to-r from-transparent via-gray200 to-transparent"></div>

                <div
                  *ngFor="let product of cartProducts; let i = index"
                  class="flex items-center justify-between gap-3 px-6">
                  <div class="flex items-center gap-2">
                    <div
                      class="relative bg-white border border-solid border-gray200 rounded-lg">
                      <button
                        *ngIf="cartProducts && cartProducts.length > 1"
                        (click)="confirmRemoveProduct(i)"
                        class="absolute -top-2 -right-2 rounded-full transition-all bg-red300 hover:bg-red400 w-5 h-5 flex items-center justify-center cursor-pointer">
                        <i
                          class="pi pi-times"
                          style="color: white; font-size: 0.75rem"></i>
                      </button>
                      <img
                        [src]="getImageUrl(product.images)"
                        [alt]="product.title"
                        class="w-14" />
                    </div>
                    <p>{{ product.title }}</p>
                    <p>{{ product.quantity }}</p>
                  </div>
                  <div>
                    <p class="text-nowrap">
                      R$ {{ product.price | number: '1.2-2' }}
                    </p>
                  </div>
                </div>

                <div class="flex gap-3 px-6">
                  <div class="flex-1">
                    <app-input
                      type="text"
                      placeholder="Código de desconto ou vale-presente"
                      size="small"
                      color="white"
                      className="w-full"
                      required />
                  </div>

                  <button
                    class="bg-white500 border border-solid border-gray200 rounded-2xl text-gray400 font-medium px-6">
                    Aplicar
                  </button>
                </div>

                <div class="flex items-center justify-between gap-4 px-6">
                  <p>Subtotal</p>
                  <p>R$ {{ getSubtotal() | number: '1.2-2' }}</p>
                </div>

                <div class="flex items-center justify-between gap-4 px-6 pb-4">
                  <div class="flex items-center gap-2">
                    <p>Envio</p>
                    <i
                      class="pi pi-question-circle"
                      pTooltip="Exemplo texto"
                      tooltipPosition="bottom"></i>
                  </div>
                  <p>
                    {{
                      selectedShippingMethod
                        ? getShipping() === 0
                          ? 'Grátis'
                          : 'R$ ' + (getShipping() | number: '1.2-2')
                        : 'Insira o endereço de entrega'
                    }}
                  </p>
                </div>

                <div
                  class="w-full h-[1px] bg-gradient-to-r from-transparent via-gray200 to-transparent"></div>

                <div class="flex items-center justify-between gap-4 px-6 pb-6">
                  <p class="font-semibold text-lg">Total</p>
                  <p class="font-semibold text-lg">
                    R$ {{ getTotalWithShipping() | number: '1.2-2' }}
                  </p>
                </div>
              </div>
            </div>

            <div
              class="block lg:hidden w-full h-[1px] bg-gradient-to-r from-transparent via-rose300 to-transparent my-3"></div>

            <app-button
              size="small"
              color="green"
              className="w-full transition-all font-semibold h-12 mt-4"
              *ngIf="currentStep === 4"
              (click)="nextStep()"
              >Confirmar a compra</app-button
            >

            <div class="flex items-center gap-3 pt-4 pb-10">
              <a href="" class="text-black500 underline text-sm"
                >Política de Reembolso</a
              >
              <a href="" class="text-black500 underline text-sm"
                >Termos de Serviço</a
              >
              <a href="" class="text-black500 underline text-sm"
                >Política de Privacidade</a
              >
            </div>
          </p-accordion>
        </div>

        <div
          class="h-100 w-[1px] bg-gradient-to-t from-transparent via-rose300 to-transparent hidden lg:block"></div>

        <!-- Responsive desktop -->
        <div class="flex-1 hidden lg:block">
          <div
            class="bg-white300 clip-path-resume flex flex-col gap-3 sticky top-5">
            <div class="pt-4 px-6">
              <p class="font-semibold text-xl">Resumo da compra</p>
            </div>

            <div
              class="w-full h-[1px] bg-gradient-to-r from-transparent via-gray200 to-transparent"></div>

            <div
              *ngFor="let product of cartProducts; let i = index"
              class="flex items-center justify-between gap-3 px-6">
              <div class="flex items-center gap-2">
                <div
                  class="relative bg-white border border-solid border-gray200 rounded-lg">
                  <button
                    *ngIf="cartProducts && cartProducts.length > 1"
                    (click)="confirmRemoveProduct(i)"
                    class="absolute -top-2 -right-2 rounded-full transition-all bg-red300 hover:bg-red400 w-5 h-5 flex items-center justify-center cursor-pointer">
                    <i
                      class="pi pi-times"
                      style="color: white; font-size: 0.75rem"></i>
                  </button>
                  <img
                    [src]="getImageUrl(product.images)"
                    [alt]="product.title"
                    class="w-14" />
                </div>
                <p>{{ product.title }}</p>
                <p *ngIf="product.quantity !== 1">x{{ product.quantity }}</p>
              </div>
              <div>
                <p class="text-nowrap">
                  R$ {{ product.price | number: '1.2-2' }}
                </p>
              </div>
            </div>

            <div class="flex gap-3 px-6">
              <div class="flex-1">
                <app-input
                  type="text"
                  placeholder="Código de desconto ou vale-presente"
                  size="small"
                  color="white"
                  className="w-full"
                  required />
              </div>

              <button
                class="bg-white500 border border-solid border-gray200 rounded-2xl text-gray400 font-medium px-6">
                Aplicar
              </button>
            </div>

            <div class="flex items-center justify-between gap-4 px-6">
              <p>Subtotal</p>
              <p>R$ {{ getSubtotal() | number: '1.2-2' }}</p>
            </div>

            <div class="flex items-center justify-between gap-4 px-6 pb-4">
              <div class="flex items-center gap-2">
                <p>Envio</p>
                <i
                  class="pi pi-question-circle"
                  pTooltip="Exemplo texto"
                  tooltipPosition="bottom"></i>
              </div>
              <p>
                {{
                  selectedShippingMethod
                    ? getShipping() === 0
                      ? 'Grátis'
                      : 'R$ ' + (getShipping() | number: '1.2-2')
                    : 'Insira o endereço de entrega'
                }}
              </p>
            </div>

            <div
              class="w-full h-[1px] bg-gradient-to-r from-transparent via-gray200 to-transparent"></div>

            <div class="flex items-center justify-between gap-4 px-6 pb-6">
              <p class="font-semibold text-lg">Total</p>
              <p class="font-semibold text-lg">
                R$ {{ getTotalWithShipping() | number: '1.2-2' }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<p-confirmDialog [closable]="false"></p-confirmDialog>
<p-toast></p-toast>
