<div class="bg-white300">
  <div class="container py-6 px-2 sm:px-6">
    <div class="bg-white clip-path-box">
      <div class="flex items-center justify-between py-6 px-6 pl-14">
        <img src="assets/svg/icons/logo-example.svg" alt="Logo" />
        <img
          src="assets/svg/icons/pagamento-seguro.svg"
          alt="Pagamento Seguro" />
      </div>

      <!-- <div
        class="w-full h-[1px] bg-gradient-to-r from-transparent via-rose300 to-transparent my-3"></div> -->
      <div class="flex justify-center">
        <img
          src="assets/svg/icons/line-horizontal.svg"
          alt="Pagamento Seguro"
          class="max-w-[1200px] w-full" />
      </div>

      <div
        class="h-100 w-[1px] bg-gradient-to-t from-transparent via-rose300 to-transparent hidden lg:block"></div>

      <div class="md:flex justify-center gap-10 py-4 px-6 mt-6">
        <div class="flex-1">
          <!-- Sessão Contato -->
          <div>
            <div class="flex items-center gap-6">
              <div class="min-w-[48px] h-[48px]">
                <img src="assets/svg/icons/confirm-icon.svg" alt="" />
              </div>

              <div>
                <p class="text-gray400">Confirmação de pagamento #DQFDHG5E0</p>
                <p class="font-semibold text-lg">Obrigado, name!</p>
              </div>
            </div>

            <div
              class="p-4 border border-solid border-gray100 rounded-md mt-8 mb-4">
              <p class="font-semibold">Seu pedido foi confirmado</p>
              <p class="text-gray400">
                Você receberá um e-mail de confirmação com o número do seu
                pedido em breve.
              </p>
            </div>

            <div
              class="border border-solid border-gray100 rounded-md mb-8 overflow-hidden">
              <p class="font-semibold px-4 pt-4">Detalhes do pedido:</p>

              <div class="flex p-4">
                <div class="flex-1 flex flex-col gap-4">
                  <div>
                    <p class="text-gray400">Contato</p>
                    <p>Walter Santos</p>
                  </div>
                  <div>
                    <p class="text-gray400">Endereço de compra</p>
                    <div class="leading-[1.25rem]">
                      <p>Rua João Sales,</p>
                      <p>192, Bela Vista</p>
                      <p>São Paulo, São Paulo</p>
                      <p>CEP: 13.340-007</p>
                      <p>Brasil</p>
                    </div>
                  </div>
                  <div>
                    <p class="text-gray400">Método de envio</p>
                    <p>Sedex</p>
                  </div>
                </div>

                <div class="flex-1 flex flex-col gap-4">
                  <div>
                    <p class="text-gray400">Método de pagamento</p>
                    <p>Visa •••• 1234 - R$174,99</p>
                  </div>
                  <div>
                    <p class="text-gray400">Endereço de fatura</p>
                    <div class="leading-[1.25rem]">
                      <p>Rua João Sales,</p>
                      <p>192, Bela Vista</p>
                      <p>São Paulo, São Paulo</p>
                      <p>CEP: 13.340-007</p>
                      <p>Brasil</p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="border-t border-solid border-gray100 p-4 bg-white400">
                <div class="flex align-items-center gap-2">
                  <p-checkbox
                    formControlName="saveInfo"
                    value="saveInfo"
                    inputId="saveInfo" />
                  <label for="saveInfo">
                    Salve minhas informações para uma finalização de compra mais
                    rápida.</label
                  >
                </div>
              </div>
            </div>

            <div class="flex items-center justify-between">
              <p>
                Precisa de ajuda?
                <span class="text-green300 underline cursor-pointer"
                  >Contato</span
                >
              </p>
              <app-button
                size="small"
                color="green"
                className=" transition-all font-semibold h-12"
                >Continuar comprando</app-button
              >
            </div>

            <div
              class="w-full h-[1px] bg-gradient-to-r from-transparent via-rose300 to-transparent my-6"></div>

            <div class="flex items-center gap-3 pt-4 pb-10">
              <a href="" class="text-black500 underline text-sm"
                >Política de Reembolso</a
              >
              <a href="" class="text-black500 underline text-sm"
                >Termos de Serviço</a
              >
              <a href="" class="text-black500 underline text-sm"
                >Política de Privacidade</a
              >
            </div>
          </div>
        </div>

        <div class="flex-1 hidden lg:block">
          <div
            class="bg-white300 clip-path-resume flex flex-col gap-3 sticky top-5">
            <div class="pt-4 px-6">
              <p class="font-semibold text-xl">Resumo da compra</p>
            </div>

            <div
              class="w-full h-[1px] bg-gradient-to-r from-transparent via-gray200 to-transparent"></div>

            <div
              *ngFor="let product of cartProducts; let i = index"
              class="flex items-center justify-between gap-3 px-6">
              <div class="flex items-center gap-2">
                <div
                  class="relative bg-white border border-solid border-gray200 rounded-lg">
                  <img
                    [src]="getImageUrl(product.images)"
                    [alt]="product.title"
                    class="w-14" />
                </div>
                <p>{{ product.title }}</p>
                <p *ngIf="product.quantity !== 1">x{{ product.quantity }}</p>
              </div>
              <div>
                <p class="text-nowrap">
                  R$ {{ product.price | number: '1.2-2' }}
                </p>
              </div>
            </div>

            <div class="flex items-center justify-between gap-4 px-6">
              <p>Subtotal</p>
              <p>R$ {{ getSubtotal() | number: '1.2-2' }}</p>
            </div>

            <div class="flex items-center justify-between gap-4 px-6 pb-4">
              <div class="flex items-center gap-2">
                <p>Envio</p>
                <i
                  class="pi pi-question-circle"
                  pTooltip="Exemplo texto"
                  tooltipPosition="bottom"></i>
              </div>
              <p>
                {{
                  selectedShippingMethod
                    ? getShipping() === 0
                      ? 'Grátis'
                      : 'R$ ' + (getShipping() | number: '1.2-2')
                    : 'Insira o endereço de entrega'
                }}
              </p>
            </div>

            <div
              class="w-full h-[1px] bg-gradient-to-r from-transparent via-gray200 to-transparent"></div>

            <div class="flex items-center justify-between gap-4 px-6 pb-6">
              <p class="font-semibold text-lg">Total</p>
              <p class="font-semibold text-lg">
                R$ {{ getTotalWithShipping() | number: '1.2-2' }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<p-toast></p-toast>
