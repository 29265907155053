import { Component, LOCALE_ID, OnInit } from '@angular/core';
import { InputComponent } from '../../components/ui/input/input.component';
import { CheckboxModule } from 'primeng/checkbox';
import { ButtonComponent } from '../../components/ui/button/button.component';
import { CommonModule } from '@angular/common';
import { DropdownModule } from 'primeng/dropdown';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastModule } from 'primeng/toast';
import { AccordionModule } from 'primeng/accordion';
import {
  FormControl,
  FormGroup,
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { CepResponse, CepService } from '../../services/cep.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { TooltipModule } from 'primeng/tooltip';
import { ConfirmationService, MessageService } from 'primeng/api';
import {
  BodyCollect,
  CartProducts,
  ProductService,
  ResponseProduct,
  ShippingMethods,
} from '../../services/product.service';
import { Router } from '@angular/router';
import { debounceTime } from 'rxjs';

interface Installments {
  name: string;
  number: string;
}
interface Delivery {
  name: 'sedex' | 'pac';
  value: number;
  days: number;
}

@Component({
  selector: 'app-checkout',
  standalone: true,
  imports: [
    CommonModule,
    InputComponent,
    CheckboxModule,
    ButtonComponent,
    ReactiveFormsModule,
    DropdownModule,
    TooltipModule,
    ConfirmDialogModule,
    ToastModule,
    AccordionModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    ConfirmationService,
    MessageService,
  ],
  templateUrl: './checkout.component.html',
  styleUrl: './checkout.component.scss',
})
export class CheckoutComponent implements OnInit {
  apiProduct: ResponseProduct | null | undefined;

  form: FormGroup;
  selectedPaymentMethod: string | null = null;
  selectedShippingMethod: string | null = null;
  currentStep = 1;
  isFetchingCep = false;
  installments: Installments[] | undefined;
  cep = '-';
  shippingMethods: ShippingMethods[] | undefined;
  cartProducts: CartProducts[] | undefined;
  pixActive = false;
  cardActive = false;

  apiUrl = 'https://api-checkout.virtu.srv.br/api';
  // apiUrl = 'https://localhost:7053/api';

  constructor(
    private fb: NonNullableFormBuilder,
    private cepService: CepService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private router: Router,
    private httpClient: HttpClient,
    private productService: ProductService
  ) {
    this.installments = [
      { name: '1x de R$ 150,00', number: '1' },
      { name: '2x de R$ 75,00', number: '2' },
      { name: '3x de R$ 39,00', number: '3' },
      { name: '4x de R$ 22,00', number: '4' },
      { name: '5x de R$ 15,00', number: '5' },
    ];

    this.form = this.fb.group({
      productId: [null],
      step1: this.fb.group({
        email: ['', [Validators.required, Validators.email]],
        newsletter: [false],
      }),
      step2: this.fb.group({
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        street: ['', Validators.required],
        state: ['', [Validators.required, Validators.pattern('^[A-Za-z]{2}$')]],
        city: ['', Validators.required],
        cep: [
          '',
          [Validators.required, Validators.pattern('[0-9]{5}-[0-9]{3}')],
        ],
        phone: [
          '',
          [
            Validators.required,
            Validators.pattern(/^\(\d{2}\) \d{4,5}-\d{4}$/),
          ],
        ],
        billingAddress: [false],
      }),
      step3: this.fb.group({
        shippingMethod: ['', Validators.required],
      }),
      step4: this.fb.group({
        paymentMethod: ['', Validators.required],
        cardNumber: [
          '',
          [Validators.required, Validators.pattern(/^\d{13,19}$/)],
        ],
        expiryDate: [
          '',
          [Validators.required, Validators.pattern(/^(0[1-9]|1[0-2])\/\d{2}$/)],
        ],
        cvv: ['', [Validators.required, Validators.pattern(/^\d{3,4}$/)]],
        cardHolderName: ['', Validators.required],
        cardHolderCpf: ['', [Validators.required]],
        selectedInstallment: new FormControl<Installments | null>(null),
        billingAddress: [false],
      }),
    });
  }

  ngOnInit(): void {
    this.productService.apiProduct$.subscribe(product => {
      this.apiProduct = product;
      this.cartProducts = product?.cartProducts;
      this.shippingMethods = product?.shippingMethods;
      this.pixActive = product?.pixActive ? true : false;
      this.cardActive = product?.cardActive ? true : false;
    });

    if (!this.apiProduct) {
      this.productService
        .fetchProduct(sessionStorage.getItem('c_session_id'), null)
        .subscribe({
          next: (data: ResponseProduct) => {
            console.log('Resposta da API:', data);

            this.productService.setApiProduct(data);

            sessionStorage.setItem('c_session_id', data.sessionId);

            this.router.navigate(['/checkout']);
          },
          error: error => {
            console.error('Erro na requisição:', error);
          },
        });
    } else {
      console.log('Dados recebidos:', this.apiProduct);
    }

    this.form.valueChanges
      .pipe(debounceTime(300)) // Aguarda 300ms antes de emitir
      .subscribe(formData => {
        this.sendFormDataToBackend(formData);
      });
  }

  sendFormDataToBackend(formData: FormGroup) {
    const mappedData: BodyCollect = this.mapFormDataToBodyCollect(formData);

    this.productService.sendCollect(mappedData).subscribe({
      next: (response: any) => {
        console.log('Dados armazenados com sucesso:', response);
      },
      error: (error: HttpErrorResponse) => {
        console.error('Erro ao salvar dados do carrinho:', error);
      },
    });
  }

  mapFormDataToBodyCollect(formValue: any): BodyCollect {
    console.log('aaa', formValue);
    const {
      step1: { email },
      step2: { firstName, lastName, phone, cep, city, state },
    } = formValue;

    return {
      sessionId: sessionStorage.getItem('c_session_id')?.toString() || '',
      firstName,
      lastName,
      email,
      phone,
      postalCode: cep,
      neightboard: '',
      city,
      state,
    };
  }

  getIpAddress(): string {
    return '000.0.0.0';
  }

  nextStep() {
    if (this.isStepValid(this.currentStep)) {
      this.currentStep++;
    } else if (this.currentStep === 3) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Atenção',
        detail: 'Selecione um método de envio!',
      });
      return;
    } else if (this.currentStep === 4 && !this.selectedPaymentMethod) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Atenção',
        detail: 'Selecione um método de pagamento!',
      });
      return;
    } else if (this.currentStep === 4 && this.selectedPaymentMethod) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Atenção',
        detail: 'Preencha todos os campos do cartão de crédito!',
      });
      return;
    } else {
      this.messageService.add({
        severity: 'warn',
        summary: 'Atenção',
        detail: 'Preencha todos os campos!',
      });
    }
  }

  isStepValid(step: number): boolean {
    const groupName = `step${step}`;
    const group = this.form.get(groupName) as FormGroup;
    return group?.valid || false;
  }

  validateStepFields(step: number): void {
    for (let i = 1; i <= step; i++) {
      const groupName = `step${i}`;
      const group = this.form.get(groupName) as FormGroup;
      if (group) {
        Object.values(group.controls).forEach(control =>
          control.markAsTouched()
        );
      }
    }
  }

  submit(): void {
    this.validateStepFields(this.currentStep);

    if (this.isStepValid(this.currentStep)) {
      console.log('Form submitted', this.form.value);
    } else {
      console.log('Form has errors');
    }
  }

  getImageUrl(images: { isFirst: boolean; imageUrl: string }[]): string {
    // Encontra a imagem com isFirst igual a true
    const firstImage = images.find(image => image.isFirst);
    // Retorna a imagem encontrada ou a primeira da lista se não estiver nenhuma com isFirst
    return firstImage ? firstImage.imageUrl : images[0]?.imageUrl;
  }

  onCepChange(event: any) {
    const cep = (event.target as HTMLInputElement).value;

    if (cep.length === 9 && !this.isFetchingCep) {
      this.cep = cep;

      this.isFetchingCep = true;

      this.cepService.fetchAddressByCep(cep).subscribe({
        next: (data: CepResponse) => {
          const formattedCep = this.formatCep(data.cep);

          this.form.get('step2')?.patchValue({
            cep: formattedCep,
            state: data.state,
            city: data.city,
            street: data.street,
          });

          this.isFetchingCep = false;
        },
        error: (err: HttpErrorResponse) => {
          // alert(err.message);

          this.isFetchingCep = false;
        },
      });
    }
  }

  formatCep(cep: string): string {
    return cep.replace(/^(\d{5})(\d{3})$/, '$1-$2');
  }

  selectShippingMethod(method: string): void {
    this.selectedShippingMethod = method;
  }

  selectPaymentMethod(method: 'pix' | 'boleto' | 'credit'): void {
    this.selectedPaymentMethod = method;
    this.updateCardFieldsValidation(method);
  }

  updateCardFieldsValidation(paymentMethod: string | null): void {
    const step4Group = this.form.get('step4') as FormGroup;

    if (paymentMethod === 'credit') {
      // Torna os campos obrigatórios
      step4Group
        .get('cardNumber')
        ?.setValidators([
          Validators.required,
          Validators.pattern(/^\d{13,19}$/),
        ]);
      step4Group
        .get('expiryDate')
        ?.setValidators([
          Validators.required,
          Validators.pattern(/^(0[1-9]|1[0-2])\/\d{2}$/),
        ]);
      step4Group
        .get('cvv')
        ?.setValidators([Validators.required, Validators.pattern(/^\d{3,4}$/)]);
      step4Group.get('cardHolderName')?.setValidators(Validators.required);
      step4Group.get('cardHolderCpf')?.setValidators(Validators.required);
      step4Group.get('selectedInstallment')?.setValidators(Validators.required);
    } else {
      // Remove as validações de obrigatoriedade se não for cartão de crédito
      step4Group.get('cardNumber')?.clearValidators();
      step4Group.get('expiryDate')?.clearValidators();
      step4Group.get('cvv')?.clearValidators();
      step4Group.get('cardHolderName')?.clearValidators();
      step4Group.get('cardHolderCpf')?.clearValidators();
      step4Group.get('selectedInstallment')?.clearValidators();
    }

    // Atualiza o estado de validação do formulário após modificar as validações
    step4Group.get('cardNumber')?.updateValueAndValidity();
    step4Group.get('expiryDate')?.updateValueAndValidity();
    step4Group.get('cvv')?.updateValueAndValidity();
    step4Group.get('cardHolderName')?.updateValueAndValidity();
    step4Group.get('cardHolderCpf')?.updateValueAndValidity();
    step4Group.get('selectedInstallment')?.updateValueAndValidity();
  }

  getSubtotal(): number {
    return this.productService.getSubtotal(this.cartProducts);
  }

  getShipping(): number {
    return this.productService.getShipping(
      this.shippingMethods,
      this.selectedShippingMethod
    );
  }

  getTotalWithShipping(): number {
    return this.productService.getTotalWithShipping(
      this.cartProducts,
      this.shippingMethods,
      this.selectedShippingMethod
    );
  }

  confirmRemoveProduct(index: number) {
    this.confirmationService.confirm({
      message: 'O item será removido do seu carrinho',
      header: 'Você tem certeza que deseja remover este item?',
      icon: 'pi pi-info-circle',
      acceptLabel: 'Confirmar',
      rejectLabel: 'Cancelar',
      acceptIcon: 'none',
      rejectIcon: 'none',
      acceptButtonStyleClass: 'p-button-success',
      rejectButtonStyleClass: 'p-button-cancel',
      accept: () => {
        this.removeProduct(index);
      },
    });
  }

  // Função para remover o produto do array
  removeProduct(index: number) {
    this.cartProducts?.splice(index, 1);
    this.messageService.add({
      severity: 'success',
      summary: 'Sucesso',
      detail: 'Produto removido do carrinho',
    });
  }
}
