import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ProductService,
  ResponseProduct,
} from '../../services/product.service';

@Component({
  selector: 'app-get-product',
  standalone: true,
  imports: [],
  templateUrl: './get-product.component.html',
  styleUrl: './get-product.component.scss',
})
export class GetProductComponent implements OnInit {
  form!: FormGroup;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private productService: ProductService
  ) {}

  ngOnInit(): void {
    const productId = this.route.snapshot.paramMap.get('cartProductId'); // Captura o código da URL
    console.log('Código capturado:', productId);

    this.form = this.fb.group({
      productId: [productId], // Inicializa o formulário com o valor capturado
    });

    this.fetchProduct(productId);
  }

  fetchProduct(productId: string | null): void {
    if (!productId) {
      console.error('ID do produto não encontrado.');
      return;
    }

    this.productService
      .fetchProduct(sessionStorage.getItem('c_session_id'), productId)
      .subscribe({
        next: (data: ResponseProduct) => {
          console.log('Resposta da API:', data);

          this.productService.setApiProduct(data);

          sessionStorage.setItem('c_session_id', data.sessionId);

          this.router.navigate(['/checkout']);
        },
        error: error => {
          console.error('Erro na requisição:', error);
        },
      });
  }
}
