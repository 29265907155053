<div class="bg-white300">
  <div class="container py-6 px-2 sm:px-6">
    <div class="bg-white clip-path-box">
      <div class="flex items-center justify-between py-6 px-6 pl-14">
        <img src="assets/svg/icons/logo-example.svg" alt="Logo" />
        <img
          src="assets/svg/icons/pagamento-seguro.svg"
          alt="Pagamento Seguro" />
      </div>

      <!-- <div
        class="w-full h-[1px] bg-gradient-to-r from-transparent via-rose300 to-transparent my-3"></div> -->
      <div class="flex justify-center">
        <img
          src="assets/svg/icons/line-horizontal.svg"
          alt="Pagamento Seguro"
          class="max-w-[1200px] w-full" />
      </div>

      <div
        class="h-100 w-[1px] bg-gradient-to-t from-transparent via-rose300 to-transparent hidden lg:block"></div>

      <div class="md:flex justify-center gap-10 py-4 px-6 mt-6">
        <div class="flex-1">
          <!-- Sessão Contato -->
          <div>
            <div class="flex items-center gap-6">
              <div class="min-w-[48px] h-[48px]">
                <img src="assets/svg/icons/dots-icon.svg" alt="" />
              </div>

              <p class="font-semibold">
                Pague R$ 174,99 via Pix para garantir sua compra
              </p>
            </div>

            <div
              class="w-full h-[1px] bg-gradient-to-r from-transparent via-rose300 to-transparent mt-10 mb-4"></div>

            <div class="flex flex-col gap-4">
              <p class="font-semibold">Escaneie este código QR para pagar</p>

              <div class="text-sm">
                <p>Acesse seu Internet Banking ou app de pagamentos.</p>
                <p>Escolha pagar via Pix.</p>
                <p>Escaneie o seguinte código:</p>
              </div>
            </div>

            <div
              class="w-full h-[1px] bg-gradient-to-r from-transparent via-rose300 to-transparent my-6"></div>

            <div class="flex flex-col gap-4">
              <div class="flex justify-center">
                <img src="assets/images/qrcode.png" alt="" />
              </div>

              <div class="flex items-center gap-2">
                <i class="pi pi-clock" style="font-size: 0.75rem"></i>
                <p class="text-sm">Pague e será creditado na hora.</p>
              </div>
            </div>

            <div
              class="w-full h-[1px] bg-gradient-to-r from-transparent via-rose300 to-transparent my-6"></div>

            <div class="flex flex-col gap-4">
              <p class="font-semibold">
                Ou copie este código para fazer o pagamento
              </p>
              <p class="text-sm">
                Escolha pagar via Pix pelo seu Internet Banking ou app de
                pagamentos. Depois, cole o seguinte código:
              </p>

              <div
                class="flex items-center border border-solid border-gray200 rounded-lg gap-4 px-4 py-2">
                <p class="flex-1 break-all leading-4 text-xs">
                  00020126540014br.gov.bcb.pix0132pix_marketplacevendendomais.com524
                  {{ pixCode }}
                </p>
                <button
                  class="font-semibold text-green300"
                  (click)="copyPixCode()">
                  Copiar
                </button>
              </div>

              <div class="flex items-center gap-4">
                <app-button
                  size="small"
                  color="green"
                  className="w-full transition-all font-semibold h-12"
                  (click)="copyPixCode()"
                  >Copiar Código Pix</app-button
                >
                <button class="font-semibold text-green300 underline">
                  Ver em minhas compras
                </button>
              </div>
            </div>

            <div
              class="w-full h-[1px] bg-gradient-to-r from-transparent via-rose300 to-transparent my-6"></div>

            <div class="flex items-center gap-3 pt-4 pb-10">
              <a href="" class="text-black500 underline text-sm"
                >Política de Reembolso</a
              >
              <a href="" class="text-black500 underline text-sm"
                >Termos de Serviço</a
              >
              <a href="" class="text-black500 underline text-sm"
                >Política de Privacidade</a
              >
            </div>
          </div>
        </div>

        <div class="flex-1 hidden lg:block">
          <div
            class="bg-white300 clip-path-resume flex flex-col gap-3 sticky top-5">
            <div class="pt-4 px-6">
              <p class="font-semibold text-xl">Resumo da compra</p>
            </div>

            <div
              class="w-full h-[1px] bg-gradient-to-r from-transparent via-gray200 to-transparent"></div>

            <div
              *ngFor="let product of cartProducts; let i = index"
              class="flex items-center justify-between gap-3 px-6">
              <div class="flex items-center gap-2">
                <div
                  class="relative bg-white border border-solid border-gray200 rounded-lg">
                  <img
                    [src]="getImageUrl(product.images)"
                    [alt]="product.title"
                    class="w-14" />
                </div>
                <p>{{ product.title }}</p>
                <p *ngIf="product.quantity !== 1">x{{ product.quantity }}</p>
              </div>
              <div>
                <p class="text-nowrap">
                  R$ {{ product.price | number: '1.2-2' }}
                </p>
              </div>
            </div>

            <div class="flex gap-3 px-6">
              <div class="flex-1">
                <app-input
                  type="text"
                  placeholder="Código de desconto ou vale-presente"
                  size="small"
                  color="white"
                  className="w-full"
                  required />
              </div>

              <button
                class="bg-white500 border border-solid border-gray200 rounded-2xl text-gray400 font-medium px-6">
                Aplicar
              </button>
            </div>

            <div class="flex items-center justify-between gap-4 px-6">
              <p>Subtotal</p>
              <p>R$ {{ getSubtotal() | number: '1.2-2' }}</p>
            </div>

            <div class="flex items-center justify-between gap-4 px-6 pb-4">
              <div class="flex items-center gap-2">
                <p>Envio</p>
                <i
                  class="pi pi-question-circle"
                  pTooltip="Exemplo texto"
                  tooltipPosition="bottom"></i>
              </div>
              <p>
                {{
                  selectedShippingMethod
                    ? getShipping() === 0
                      ? 'Grátis'
                      : 'R$ ' + (getShipping() | number: '1.2-2')
                    : 'Insira o endereço de entrega'
                }}
              </p>
            </div>

            <div
              class="w-full h-[1px] bg-gradient-to-r from-transparent via-gray200 to-transparent"></div>

            <div class="flex items-center justify-between gap-4 px-6 pb-6">
              <p class="font-semibold text-lg">Total</p>
              <p class="font-semibold text-lg">
                R$ {{ getTotalWithShipping() | number: '1.2-2' }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<p-toast></p-toast>
