import { Component, LOCALE_ID, OnInit } from '@angular/core';
import {
  CartProducts,
  ProductService,
  ResponseProduct,
  ShippingMethods,
} from '../../services/product.service';
import { InputComponent } from '../../components/ui/input/input.component';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from '../../components/ui/button/button.component';
import { ToastModule } from 'primeng/toast';

@Component({
  selector: 'app-pix-payment',
  standalone: true,
  imports: [InputComponent, CommonModule, ButtonComponent, ToastModule],
  templateUrl: './pix-payment.component.html',
  styleUrl: './pix-payment.component.scss',
  providers: [
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    ConfirmationService,
    MessageService,
  ],
})
export class PixPaymentComponent implements OnInit {
  cartProducts: CartProducts[] | undefined;
  shippingMethods: ShippingMethods[] | undefined;
  selectedShippingMethod = '';
  pixCode =
    '00020126540014br.gov.bcb.pix0132pix_marketplacevendendomais.com524';

  constructor(
    private productService: ProductService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    const savedResponse = localStorage.getItem('c_response');

    if (savedResponse) {
      const responseData: ResponseProduct = JSON.parse(savedResponse);
      console.log('Dados carregados do localStorage:', responseData);
      this.cartProducts = responseData.cartProducts;
      this.shippingMethods = responseData.shippingMethods;
      this.selectedShippingMethod = 'Teste';
    } else {
      console.error('Nenhuma resposta encontrada no localStorage.');
    }
  }

  copyPixCode() {
    navigator.clipboard
      .writeText(this.pixCode)
      .then(() => {
        this.messageService.add({
          severity: 'success',
          summary: 'Sucesso!',
          detail: 'Código pix copiado.',
        });
      })
      .catch(() => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Erro ao copiar código pix.',
        });
      });
  }

  getImageUrl(images: { isFirst: boolean; imageUrl: string }[]): string {
    // Encontra a imagem com isFirst igual a true
    const firstImage = images.find(image => image.isFirst);
    // Retorna a imagem encontrada ou a primeira da lista se não estiver nenhuma com isFirst
    return firstImage ? firstImage.imageUrl : images[0]?.imageUrl;
  }

  getSubtotal(): number {
    return this.productService.getSubtotal(this.cartProducts);
  }

  getShipping(): number {
    return this.productService.getShipping(
      this.shippingMethods,
      this.selectedShippingMethod
    );
  }

  getTotalWithShipping(): number {
    return this.productService.getTotalWithShipping(
      this.cartProducts,
      this.shippingMethods,
      this.selectedShippingMethod
    );
  }
}
