import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, Observable, throwError } from 'rxjs';

export interface CartProducts {
  images: {
    isFirst: boolean;
    imageUrl: string;
  }[];
  description: string;
  title: string;
  price: number;
  quantity: number;
}

export interface ShippingMethods {
  title: string;
  price: number;
  minDays: number;
  maxDays: number;
}

export interface ShopConfiguration {
  headerColor: string;
  footerType: string;
  footerColor: string;
  headerType: string;
}

export interface ResponseProduct {
  sessionId: string;
  cartProducts: CartProducts[];
  shippingMethods: ShippingMethods[];
  pixActive: boolean;
  cardActive: boolean;
  cardInstallments: number;
  shopConfiguration: ShopConfiguration;
}

export interface BodyCollect {
  sessionId: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  postalCode: string;
  neightboard: string;
  city: string;
  state: string;
}

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  private apiUrl = 'https://api-checkout.virtu.srv.br';
  // private apiUrl = 'https://localhost:7053';

  private apiProductSource = new BehaviorSubject<ResponseProduct | null>(null);
  apiProduct$ = this.apiProductSource.asObservable();

  constructor(private http: HttpClient) {}

  fetchProduct(
    sessionId: string | null,
    cartProductId: string | null
  ): Observable<ResponseProduct> {
    return this.http
      .post<ResponseProduct>(`${this.apiUrl}/api/carts/store`, {
        sessionId,
        cartProductId,
      })
      .pipe(
        catchError(error => {
          console.error('Erro ao buscar produtos:', error.message || error);
          return throwError(() => new Error('Produtos não encontrado.'));
        })
      );
  }

  sendCollect(formData: BodyCollect): Observable<ResponseProduct> {
    return this.http.post<ResponseProduct>(`${this.apiUrl}/api/carts/collect`, formData);
  }

  getSubtotal(cartProducts: CartProducts[] | undefined): number {
    return (
      cartProducts?.reduce(
        (total, product) => total + product.price * product.quantity,
        0
      ) || 0
    );
  }

  getShipping(
    shippingMethods: ShippingMethods[] | undefined,
    selectedShippingMethod: string | null
  ): number {
    return (
      shippingMethods?.find(
        delivery => delivery.title === selectedShippingMethod
      )?.price || 0
    );
  }

  getTotalWithShipping(
    cartProducts: CartProducts[] | undefined,
    shippingMethods: ShippingMethods[] | undefined,
    selectedShippingMethod: string | null
  ): number {
    const subtotal = this.getSubtotal(cartProducts);
    const shipping = this.getShipping(shippingMethods, selectedShippingMethod);
    return subtotal + shipping;
  }

  setApiProduct(product: ResponseProduct): void {
    this.apiProductSource.next(product);
  }

  getApiProduct(): ResponseProduct | null {
    return this.apiProductSource.value;
  }
}
