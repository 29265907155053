import { Component, OnInit } from '@angular/core';
import {
  CartProducts,
  ProductService,
  ResponseProduct,
  ShippingMethods,
} from '../../services/product.service';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { ButtonComponent } from '../../components/ui/button/button.component';
import { CommonModule } from '@angular/common';
import { InputComponent } from '../../components/ui/input/input.component';
import { CheckboxModule } from 'primeng/checkbox';

@Component({
  selector: 'app-confirmation',
  standalone: true,
  imports: [
    InputComponent,
    CommonModule,
    ButtonComponent,
    ToastModule,
    CheckboxModule,
  ],
  templateUrl: './confirmation.component.html',
  styleUrl: './confirmation.component.scss',
})
export class ConfirmationComponent implements OnInit {
  cartProducts: CartProducts[] | undefined;
  shippingMethods: ShippingMethods[] | undefined;
  selectedShippingMethod = '';

  constructor(
    private productService: ProductService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    const savedResponse = localStorage.getItem('c_response');

    if (savedResponse) {
      const responseData: ResponseProduct = JSON.parse(savedResponse);
      console.log('Dados carregados do localStorage:', responseData);
      this.cartProducts = responseData.cartProducts;
      this.shippingMethods = responseData.shippingMethods;
      this.selectedShippingMethod = 'Teste';
    } else {
      console.error('Nenhuma resposta encontrada no localStorage.');
    }
  }

  getImageUrl(images: { isFirst: boolean; imageUrl: string }[]): string {
    // Encontra a imagem com isFirst igual a true
    const firstImage = images.find(image => image.isFirst);
    // Retorna a imagem encontrada ou a primeira da lista se não estiver nenhuma com isFirst
    return firstImage ? firstImage.imageUrl : images[0]?.imageUrl;
  }

  getSubtotal(): number {
    return this.productService.getSubtotal(this.cartProducts);
  }

  getShipping(): number {
    return this.productService.getShipping(
      this.shippingMethods,
      this.selectedShippingMethod
    );
  }

  getTotalWithShipping(): number {
    return this.productService.getTotalWithShipping(
      this.cartProducts,
      this.shippingMethods,
      this.selectedShippingMethod
    );
  }
}
