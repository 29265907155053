import { Routes } from '@angular/router';
import { CheckoutComponent } from './pages/checkout/checkout.component';
import { GetProductComponent } from './pages/get-product/get-product.component';
import { PixPaymentComponent } from './pages/pix-payment/pix-payment.component';
import { ConfirmationComponent } from './pages/confirmation/confirmation.component';

export const routes: Routes = [
  { path: '', component: CheckoutComponent },
  { path: 'checkout/r/:cartProductId', component: GetProductComponent },
  { path: 'checkout', component: CheckoutComponent },
  { path: 'checkout/pix-payment', component: PixPaymentComponent },
  { path: 'checkout/confirmation', component: ConfirmationComponent },

  // { path: '', redirectTo: 'login', pathMatch: 'full' },
  // { path: '**', redirectTo: 'login' }, // 404
];
